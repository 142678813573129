import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { IUser } from '../../model/user';
import { AuthActions } from './actions';

export interface IAuthState {
  loginImages: string[];
  user: IUser | undefined;
  loginFailure: unknown;
}

export const initialState: IAuthState = {
  loginImages: [],
  user: undefined,
  loginFailure: undefined,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, AuthActions.restoreUser, (state, action) =>
    produce(state, (draft) => {
      draft.user = action.user;
    }),
  ),
  on(
    AuthActions.loginFailure,
    AuthActions.logout,
    AuthActions.forceLogin,
    (state) =>
      produce(state, (draft) => {
        draft.user = undefined;
      }),
  ),
  on(AuthActions.loginWithCredentials, (state) =>
    produce(state, (draft) => {
      draft.loginFailure = undefined;
    }),
  ),
  on(AuthActions.loginFailure, (state, action) =>
    produce(state, (draft) => {
      draft.loginFailure = action.error;
    }),
  ),
  on(AuthActions.tokenRefreshed, (state, action) =>
    produce(state, (draft) => {
      if (!draft.user) {
        return;
      }
      draft.user.accessToken = action.accessToken;
      draft.user.refreshToken = action.refreshToken;
    }),
  ),
  on(AuthActions.loginImagesFetchSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.loginImages = action.images;
    }),
  ),
);

export const authFeature = createFeature({
  name: 'auth',
  reducer,
});
